import React from 'react';
import { Button } from '@hagerty/react-components';

import { useUserState } from 'src/components/GlobalSiteHeader/useUserState';
import { Translate } from 'src/components/Translate';

export const RedemptionLogin = () => {
  const { login } = useUserState(location);

  return (
    <div className="mr-6">
      <Button buttonType="Primary" buttonSize="Large" onClick={login} testId="anonymous-login">
        <Translate resourceKey="redemption.details.log-in-button" />
      </Button>
    </div>
  );
};

export const RedemptionCreateAccount = () => {
  const { login } = useUserState(location);
  return (
    <Button buttonType="Outline" buttonSize="Large" onClick={login} testId="anonymous-create-account">
      <Translate resourceKey="redemption.details.create-account-button" />
    </Button>
  );
};
