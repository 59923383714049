import { useEffect, useState } from 'react';

import { useAuthContext } from 'src/components/AuthProvider';

export const useAutoLogin = () => {
  const { isAuthenticated, loading, refresh } = useAuthContext();
  const [hasCheckedUser, setHasCheckedUser] = useState(false);
  useEffect(() => {
    if (!isAuthenticated && !loading && !hasCheckedUser) {
      refresh();
      setHasCheckedUser(true);
    }
  }, [loading, isAuthenticated]);
};
