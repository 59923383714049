import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import { withPrefix } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { Profile } from 'src/api/types';
import useBenefitsPageQueries from 'src/components/benefits/useBenefitsPageQueries';
import { Membership } from 'src/components/benefits/useBenefitsPageQueries';
import useMembership from 'src/api/useMembership';
import useDateFormat from 'src/hooks/useDateFormat';
import stripes from '../../static/digital-benefits-page/stripes.svg';
import { Translate } from './Translate';

import { useTrackingContext } from 'src/components/TrackingContext';
import { useTreatments } from '@splitsoftware/splitio-react';
import { Split, Treatment } from 'src/constants/Split';

type Props = {
  title?: string;
  description?: string;
  ctaText?: string;
  ctaHref?: string;
  backgroundImage?: string;
  backgroundImageAlt?: string;
  children?: any;
  isMemberCard?: boolean;
  isMagazineCard?: boolean;
  isAppLink?: boolean;
  isTargetBlank?: boolean;
  isRedemptionPage?: boolean;
  isTitleHtml?: boolean;
};

type MembershipCardProps = {
  profile: Profile;
  membership: Membership;
  hasLegacyMembership?: boolean;
};

export const MembershipCard: React.FC<MembershipCardProps> = ({ profile, membership, hasLegacyMembership }) => {
  const { data } = useMembership();
  const format = useDateFormat();
  const { t } = useTranslation();
  const treatments = useTreatments([Split.FeaturePreventDuplicateMemberships]);
  const { trackInteraction } = useTrackingContext();

  const hasMemberGraphTreatment =
    treatments[Split.FeaturePreventDuplicateMemberships].treatment === Treatment.MemberGraph ||
    treatments[Split.FeaturePreventDuplicateMemberships].treatment === Treatment.BothMemberGraph;
  const hdcNumber = hasMemberGraphTreatment ? membership?.hdcNumber : profile?.membershipId;

  const manageLink = (
    <>
      {'Manage Membership'}
      <svg className="icon" aria-hidden>
        <use xlinkHref="#16-arrow-right" />
      </svg>
    </>
  );

  return (
    <div className="digital-benefit-card" data-cy="membership-card">
      <div
        className="digital-benefit-card__image"
        style={{ backgroundImage: `url(${withPrefix('/digital-benefits-page/member.jpg')})` }}
      >
        <div className="digital-benefit-card__stripes">
          <img src={stripes} alt="stripes" />
        </div>
        <div className="digital-benefit-card__username">
          <h2>
            {profile.givenName} {profile.familyName}
          </h2>
        </div>
      </div>
      <div className="digital-benefit-card__content">
        <div
          className="digital-benefit-card__info-box-wrap digital-benefit-card__info-box-wrap--member"
          data-cy="benefits-member-info"
        >
          <div className="digital-benefit-card__info-box">
            <Translate as={'h3'} resourceKey="Member ID" />
            <p>{hdcNumber}</p>
          </div>
          {data?.wasCanceled() ? (
            <div className="digital-benefit-card__info-box">
              <Translate resourceKey="Cancellation date" />
              <p>{format(new Date(data?.data?.canceledAt))}</p>
            </div>
          ) : !hasLegacyMembership && data?.willBeCanceled() ? (
            <div className="digital-benefit-card__info-box">
              <Translate resourceKey="Expiration date" />
              <p>{format(data?.expireAt)}</p>
            </div>
          ) : (
            membership?.renewalAt && (
              <div className="digital-benefit-card__info-box">
                <Translate as={'h3'} resourceKey="Renewal date" />
                <p>{membership.renewalAt}</p>
              </div>
            )
          )}
        </div>
        <div className="digital-benefit-card__more">
          {hasLegacyMembership ? (
            <a
              aria-label="Manage Membership"
              href={process.env.HDC_HAGERTY_ACCOUNT_PAGE}
              onClick={() => trackInteraction('link', 'Manage Membership')}
            >
              {manageLink}
            </a>
          ) : (
            <Link
              to="/my-account/"
              aria-label="Manage Membership"
              onClick={() => trackInteraction('link', 'Manage Membership')}
            >
              {manageLink}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

const DigitalBenefitCard: React.FC<Props> = ({
  title,
  description,
  ctaText,
  ctaHref,
  backgroundImage,
  children,
  isMagazineCard,
  isAppLink,
  isTargetBlank = true,
  isRedemptionPage,
  isTitleHtml,
}) => {
  const { membership } = useBenefitsPageQueries();
  const { trackInteraction } = useTrackingContext();

  const handlePageInteraction = () => {
    if (ctaHref.startsWith('mailto') || ctaHref.startsWith('tel')) {
      trackInteraction('click', ctaText);

      return;
    }

    trackInteraction('link', ctaText);
  };

  const benefitBackgroundImage = isRedemptionPage ? backgroundImage : withPrefix(`${backgroundImage}`);

  return (
    <div
      className={cx('digital-benefit-card', {
        'digital-benefit-card--non-member': !membership,
        'digital-benefit-card--redemption': isRedemptionPage,
      })}
    >
      <div className="digital-benefit-card__image" style={{ backgroundImage: `url(${benefitBackgroundImage})` }}></div>
      <div className="digital-benefit-card__content">
        <div className="digital-benefit-card__heading">
          <Translate as={'h2'} resourceKey={title} useHtml={isTitleHtml} />{' '}
          {!membership && !isRedemptionPage && <img src="/digital-benefits-page/chevron-blue.svg" alt="" />}
        </div>
        <p className="digital-benefit-card__lead">
          <Translate resourceKey={description} />
        </p>
        {children}
        {!isMagazineCard && membership && (
          <div className="digital-benefit-card__more mb-4">
            {isAppLink ? (
              <Link to={ctaHref} aria-label={ctaText} onClick={() => trackInteraction('link', ctaText)}>
                {ctaText}
                <svg className="icon">
                  <use xlinkHref="#16-arrow-right" />
                </svg>
              </Link>
            ) : (
              <a
                href={ctaHref}
                aria-label={ctaText}
                rel="noreferrer"
                target={isTargetBlank ? '_blank' : undefined}
                onClick={handlePageInteraction}
                onContextMenu={handlePageInteraction}
              >
                {ctaText}
                <svg className="icon">
                  <use xlinkHref="#16-arrow-right" />
                </svg>
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DigitalBenefitCard;
