// Defined in contentful https://app.contentful.com/spaces/w61n0jg6cdlz/entries/7pGXFKyALArZ4SircPweAg
export type Benefit = {
  title: string;
  isTitleHtml: boolean;
  description: string;
  image: string;
  cypressData?: string;
};

export type Assets = {
  benefits: Benefit[];
};

export enum AssetTitle {
  Heading = 'redemption.banner.heading',
  Benefits = 'redemption.benefits',
  Overline = 'redemption.banner.overline',
}
