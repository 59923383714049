import { useTranslation } from 'react-i18next';

const useDateFormat = () => {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  const { i18n } = useTranslation();

  return (date: Date) => date?.toLocaleDateString(i18n.language, options) || '';
};

export default useDateFormat
