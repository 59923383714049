import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useTreatments } from '@splitsoftware/splitio-react';
import { Helmet } from 'react-helmet';

import { Split, Treatment } from 'src/constants/Split';

import useBenefitsPageQueries from 'src/components/benefits/useBenefitsPageQueries';
import isBrowser from 'src/helpers/isBrowser';
import DigitalBenefitCard from 'src/components/DigitalBenefitCard';
import FadeIn from 'src/components/FadeIn';
import LoadingContainer from 'src/components/LoadingContainer';
import { useAutoLogin } from 'src/hooks/useAutoLogin';
import useRedemptionAssets from 'src/components/Redemption/useRedemptionAssets';
import { Buttons } from 'src/components/discounts/CTAs';
import { RedemptionLogin, RedemptionCreateAccount } from 'src/components/Redemption/RedemptionCtas';
import { Translate } from 'src/components/Translate';
import { SiteDisclaimer } from 'src/components/Disclaimers';
import { MainFooter } from 'src/features/footer';
import { AssetTitle } from 'src/components/Redemption/Redemption.types';
import { isReady } from 'src/components/SplitContext';
import { JoinCta } from 'src/components/JoinCta';
import { useTracking } from 'src/components/TrackingContext';
import { AlreadyMemberButton } from 'src/components/already-member-button';

const metadata = {
  title: 'Join the Club | Hagerty Drivers Club',
  description: 'Become a Hagerty Drivers Club Member.',
};

const Redemption = () => {
  useAutoLogin();
  const { membership, ready, isAuthenticated } = useBenefitsPageQueries();
  const assets = useRedemptionAssets();
  const { benefits } = assets;
  const splitIsReady = isReady();
  const treatments = useTreatments([Split.FeatureBenefitsPage]);
  const hasBenefitsPage = treatments[Split.FeatureBenefitsPage].treatment === Treatment.On;

  useTracking('benefits_redemption_view');

  const renderDigitalBenefitBoxes = () => {
    return benefits.map((item) => {
      return (
        <div
          className="digital-benefits-page__grid-item digital-benefits-page__grid-item--non-member"
          key={item.title}
          data-cy="redemption-benefit"
        >
          <DigitalBenefitCard
            title={item.title}
            description={item.description}
            backgroundImage={item.image}
            isTitleHtml={item.isTitleHtml}
            isRedemptionPage
          />
        </div>
      );
    });
  };

  useEffect(() => {
    if (!splitIsReady) return;
    if (ready && membership && hasBenefitsPage) {
      navigate('/benefits/');
    }
    if (ready && isAuthenticated && !hasBenefitsPage) {
      navigate('/', { replace: true });
    }
  }, [ready, membership, isAuthenticated, splitIsReady]);

  if (!ready) {
    const height = isBrowser ? (window.innerHeight > 440 ? window.innerHeight - 400 : 40) : 40;
    return <LoadingContainer height={height} />;
  }

  return (
    <>
      <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
      </Helmet>
      <FadeIn duration={0.3}>
        <div className="digital-benefits-page">
          <div className="digital-benefits-page__inner">
            <div className="digital-benefits-page__heading" data-cy="benefits-heading">
              <Translate className="digital-benefits-overline" as="h1" resourceKey={AssetTitle.Overline} />
              <Translate className="text-display_2" as="h2" resourceKey={AssetTitle.Heading} />
              <div className="mt-4">
                {splitIsReady && hasBenefitsPage ? (
                  <JoinCta />
                ) : (
                  <Buttons size="large">
                    <RedemptionLogin />
                    <RedemptionCreateAccount />
                  </Buttons>
                )}
              </div>
              <AlreadyMemberButton className="mt-3" />
            </div>
            <div className="digital-benefits-page__grid-container">{renderDigitalBenefitBoxes()}</div>
          </div>
          <div className="inset-l" />
          <footer className="container container_center tune-index">
            <SiteDisclaimer />
          </footer>

          <MainFooter />
        </div>
      </FadeIn>
    </>
  );
};

export default Redemption;
