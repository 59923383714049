import React from 'react';
import { useAuthContext } from 'src/components/AuthProvider';

type Props = {
  className?: string;
  theme?: 'dark' | 'light';
};

const AlreadyMemberButton = ({ className = '', theme = 'dark' }: Props) => {
  const { isAuthenticated, loading, login } = useAuthContext();

  if (loading || isAuthenticated) return null;

  return (
    <button onClick={login} data-cy="already-member" className={`already-member-button ${className}`}>
      <span className={`already-member-button__text ${theme}`}>Already a member?</span>
      <span className="">
        {' '}
        <span className={`already-member-button__underline ${theme}`}>Log in</span>
      </span>
    </button>
  );
};

export { AlreadyMemberButton };
